import React from "react";
import { Routes, Route} from "react-router-dom";
import Menu from "./components/Menu";
import Data from "./components/Data";
import UserManagement from "./components/UserManagement";
import { useAuth } from "./context/AuthContext";
import Add from "./components/Add";
import LoginPage from "./components/LoginPage";
import NoAccessPage from "./components/NoAccessPage";
import PrivateRoute from "./components/PrivateRoute";

import "./App.css";

const App = () => {
  const { userRole, userData } = useAuth();
  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/data"
          element={
            <PrivateRoute user={userData}>
              <Data />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounts"
          element={
            <PrivateRoute user={userRole === "Admin"}>
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route path="/add" element={<Add />} />
        <Route path="/no-access" element={<NoAccessPage />} />
      </Routes>
    </>
  );
};

export default App;
