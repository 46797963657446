import React, { useState, useEffect } from "react";
import { db } from "../config/firebaseConfig";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
import Add from "./Add";
import { useAuth } from "../context/AuthContext";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const { currentUserRole } = useAuth();

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return;
      }
      const usersList = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        usersList.push({
          id: doc.id,
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          role: userData.role,
        });
      });
      setUsers(usersList);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  async function deleteUserWithEmailId(email) {
    try {
      const docRef = doc(db, "users", email);
      await deleteDoc(docRef);
      console.log(`User with email ${email} deleted successfully`);
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error; 
    }
  }

  return (
    <div>
      <div className="main">
        <h3>Accounts</h3>
        <hr></hr>
        <br></br>
        <button className="formbutton" onClick={() => setIsAdding(true)}>
          Toevoegen
        </button>
        {isAdding && (
          <Add
            users={users}
            setUsers={setUsers}
            setIsAdding={setIsAdding}
            getUsers={fetchUsers}
          />
        )}
        <ul>
          {users.map((user) => (
            <div className="usercard" key={user.id}>
              <li className="userlist">
                <div>Voornaam: {user.firstname}</div>
                <div>Achternaam: {user.lastname}</div>
                <div>Email: {user.email}</div>
                <div>Rol: {user.role}</div>
              </li>
              <button className="formbutton" onClick={fetchUsers}>
                Edit
              </button>
              <button className="formbutton" onClick={deleteUserWithEmailId}>
                Delete
              </button>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserManagement;
