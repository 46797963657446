import { db, auth } from "../config/firebaseConfig";
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const useHandleGoogleSignIn = () => {
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(db, "users", user.email);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        return { userExists: true, userData: docSnap.data() };
      } else {
        return { userExists: false };
      }
    } catch (error) {
      console.error("SignIn with Google error:", error);
      return { error };
    }
  };

  return handleGoogleSignIn;
};

export const SignIn = async (setRedirect) => {
  const handleGoogleSignIn = useHandleGoogleSignIn();
  const result = await handleGoogleSignIn();
  if (result.userExists) {
    setRedirect("/data");
  } else {
    setRedirect("/no-access");
  }
};

export const handleGoogleSignOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};
