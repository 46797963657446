import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { userData, userRole } = useAuth();

  if (!userData) {
    return <Navigate to="/" />;
  } else if (userRole === "User" || userRole === "Admin") {
    return children;
  }
};

export default PrivateRoute;
