import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebaseConfig";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const [redirect, setRedirect] = useState(null);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(db, "users", user.email);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        setRedirect("/data");
      } else {
        setRedirect("/no-access");
      }
    } catch (error) {
      console.error("SignIn with Google error:", error);
    }
  };

  useEffect(() => {
    const checkUser = async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.email);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          setRedirect("/data");
        } else {
          setRedirect("/no-access");
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      checkUser(user);
    });

    return () => unsubscribe();
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div className="main">
      <br></br>
      <div className="login">Inloggen met je Buurtzorg account</div>
      <button className="login-button" onClick={handleSignIn}>
        Inloggen
      </button>
    </div>
  );
};

export default LoginPage;
