import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBlYQFaeRH13gXVMjDtfUPbeI-92SqD1zU",
  authDomain: "afasadreszoeker.firebaseapp.com",
  projectId: "afasadreszoeker",
  storageBucket: "afasadreszoeker.appspot.com",
  messagingSenderId: "447124369784",
  appId: "1:447124369784:web:6eecd21ac68d1767e4988d",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };