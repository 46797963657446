import React from "react";

const NoAccessPage = () => {
  return (
    <div className="main">
      <h2>Geen Toegang</h2>
      <div>
        Je hebt geen toegang tot deze pagina. Neem contact op met de beheerder
        als je denkt dat dit een vergissing is.
      </div>
    </div>
  );
};

export default NoAccessPage;
